import { FREQ, dispatchCustomEvent, isInt, isScrollable } from "./common/utils";
import { MediaManager } from "./common/Media";

import config from "./_config";

export default class ConflictPager {
	constructor(options) {
		options = options || {};
		this.easing = options.easing || "easeInCubic";
		this.duration = Math.max(options.duration, 0) || 800;
		this.expansionDuration = Math.max(options.expansionDuration, 0) || 500;
		this.scrollThreshold = Math.max(options.scrollThreshold, 0) || 5;
		this.countFirstPanel = options.countFirstPanel || false;

		const baseClass = "pager";
		this.classes = {
			nav: `${baseClass}-nav`,
			prev: `${baseClass}-prev`,
			next: `${baseClass}-next`,
			panel: `${baseClass}-panel`,
			current: `${baseClass}-current`,
			total: `${baseClass}-total`,
			deck: `${baseClass}-deck`,
			visible: `${baseClass}-visible`,
			inactive: `${baseClass}-inactive`,
			expanded: `${baseClass}-expanded`,
			initialized: `${baseClass}-initialized`,
		};

		/**
		 * @member {Object} els All elements acted upon by this script.
		 * @property {jQuery|HTMLElement|String} els.context Context within which to search for elements.
		 * @property {jQuery} els.deck Root element, wraps all panels and nav.
		 * @property {jQuery} els.nav Nav wrapper.
		 * @property {jQuery} els.prev Nav element, triggers this.prev().
		 * @property {jQuery} els.next Nav element, triggers this.next().
		 * @property {jQuery} els.panels
		 * @property {jQuery} els.current Nav element, shows current panel number.
		 * @property {jQuery} els.total Nav element, shows total number of panels.
		 * @property {HTMLElement} els.emitter Target for all ConflictPager events.
		 */
		this.els = {
			context: options.context || document.documentElement,
			deck: undefined,
			nav: undefined,
			prev: undefined,
			next: undefined,
			panels: undefined,
			current: undefined,
			total: undefined,
			emitter: undefined,
		};

		this._isUnbound = true;
		this._isScrollBlocked = false;
		this._isAnimating = false;
		this._delta = 0;
		this._animation = {
			visible: "translateNone",
			top: "rotation",
			bottom: "translateDown",
		};
		this._wheelDir = 1;

		this.init = this.init.bind(this);
		this.eventBindings = this.eventBindings.bind(this);
		this.initHijacking = this.initHijacking.bind(this);
		this.scrollHijacking = _.throttle(this.scrollHijacking.bind(this), FREQ.FPS_60);
		this.goTo = this.goTo.bind(this);
		this.prev = this.prev.bind(this);
		this.next = this.next.bind(this);
		this.expand = this.expand.bind(this);
		this.collapse = this.collapse.bind(this);
		this.toggleCurrent = this.toggleCurrent.bind(this);
		this.resetScroll = this.resetScroll.bind(this);
		this.checkNavigation = this.checkNavigation.bind(this);
		this.resetPanelStyle = this.resetPanelStyle.bind(this);

		this.isInitialized = false;
	}

	init() {
		jQuery($ => {
			const context = $(this.els.context || document.documentElement);
			const { els, classes } = this;
			// Dom elements
			els.deck = context.find(`.${this.classes.deck}`);
			els.nav = els.deck.find(`.${this.classes.nav}`);
			els.prev = els.deck.find(`.${this.classes.prev}`);
			els.next = els.deck.find(`.${this.classes.next}`);
			els.panels = els.deck.find(`.${this.classes.panel}`);
			els.current = els.deck.find(`.${this.classes.current}`);
			els.total = els.deck.find(`.${this.classes.total}`);
			els.emitter = els.deck[0];

			els.total.text(els.panels.length - (this.countFirstPanel ? 0 : 1));
			els.panels.css("transitionDuration", this.expansionDuration + "ms");
			this.eventBindings();
			$(window).on("resize", _.throttle(this.eventBindings, FREQ.FPS_15));
			this.isInitialized = true;
			els.deck.addClass(classes.initialized);
			els.panels.find(".pager-panel-trigger").addClass("pager-hide-js");
		});
		return this;
	}

	/**
	 * Attach and detach handlers as necessary
	 * @fires ConflictPager#pagerActivate
	 * @fires ConflictPager#pagerDeactivate
	 */
	eventBindings() {
		if (this.isActive && this._isUnbound) {
			/**
			 * @event ConflictPager#pagerActivate
			 * @type {CustomEvent}
			 */
			dispatchCustomEvent("pagerActivate", this.els.emitter, {
				bubbles: true,
			});
			//bind the animation to the window scroll event, arrows click and keyboard
			this.initHijacking();
			$(window).on("wheel", this.scrollHijacking);
			this.els.prev.on("click", this.prev);
			this.els.next.on("click", this.next);

			// $(document).on("keydown", function(event) {
			// 	if (this._isScrollBlocked) {
			// 		return;
			// 	}
			// 	if (event.which == "40" && !this.els.next.hasClass("inactive")) {
			// 		event.preventDefault();
			// 		this.next();
			// 	} else if (
			// 		event.which == "38" &&
			// 		(!this.els.prev.hasClass("inactive") ||
			// 			$(window).scrollTop() != this.els.panels.eq(0).offset().top)
			// 	) {
			// 		event.preventDefault();
			// 		this.prev();
			// 	}
			// });

			if (!MediaManager.reduceMotion()) {
				this.current.find("video").each((_i, el) => !el.error && el.play());
				this.els.panels
					.find("video")
					.each((_i, el) => (el.autoplay = true) && el.pause());
			}

			this._isUnbound = false;
			this.checkNavigation();
		} else if (!this.isActive && !this._isUnbound) {
			/**
			 * @event ConflictPager#pagerDeactivate
			 * @type {CustomEvent}
			 */
			dispatchCustomEvent("pagerDeactivate", this.els.emitter, {
				bubbles: true,
			});
			//reset and unbind
			this.resetPanelStyle();
			$(window).off("wheel", this.scrollHijacking);
			this.els.prev.off("click", this.prev);
			this.els.next.off("click", this.next);
			// $(document).off("keydown");
			this.els.panels.find("video").each((_i, el) => el.pause());
			this._isUnbound = true;
		}
	}

	initHijacking() {
		// initialize section style - scrollhijacking
		const currentPanel = this.current,
			panelsAbove = currentPanel.prevAll(`.${this.classes.panel}`),
			panelsBelow = currentPanel.nextAll(`.${this.classes.panel}`);

		currentPanel
			.children()
			.first()
			.velocity(this._animation.visible, 1, function() {
				currentPanel.css("opacity", 1);
				panelsAbove.css("opacity", 1);
				panelsBelow.css("opacity", 1);
			});
		panelsAbove
			.children()
			// .first()
			.velocity(this._animation.top, 0);
		panelsBelow
			.children()
			// .first()
			.velocity(this._animation.bottom, 0);
		this._currentScroller = currentPanel.find(".header-content-scroll")[0];
	}

	scrollHijacking(event) {
		if (this._isScrollBlocked || this._isAnimating) {
			return;
		}
		// on mouse scroll - check if animate section
		if (
			event.originalEvent.detail < 0 ||
			event.originalEvent.deltaY * this._wheelDir < 0
		) {
			if (
				this._currentScroller &&
				isScrollable(this._currentScroller).y &&
				this._currentScroller.scrollTop
			) {
				return;
			}
			this._delta--;
			if (Math.abs(this._delta) >= this.scrollThreshold) {
				this.prev();
			}
		} else {
			if (
				this._currentScroller &&
				isScrollable(this._currentScroller).y &&
				this._currentScroller.scrollHeight - this._currentScroller.scrollTop !==
					this._currentScroller.clientHeight
			) {
				return;
			}
			this._delta++;
			if (this._delta >= this.scrollThreshold) {
				this.next();
			}
		}
	}

	/**
	 * The current panel.
	 * @returns {jQuery}
	 */
	get current() {
		if (!this.els.panels) {
			return undefined;
		}
		return this.els.panels.filter(`.${this.classes.visible}`);
	}

	/**
	 * The current panel index.
	 * @returns {jQuery}
	 */
	get index() {
		if (!this.els.panels) {
			return undefined;
		}
		return this.els.panels.index(this.current);
	}

	/**
	 * @returns {jQuery}
	 */
	get root() {
		return this.els.deck;
	}

	/**
	 * Handles transitioning from one conflict to another.
	 * @param {jQuery} panel The panel to transition to.
	 * @fires ConflictPager#pagerTransitionStart
	 * @fires ConflictPager#pagerTransitionEnd
	 */
	goTo(panel, callback) {
		if (!this.isActive || this.freeze) {
			return;
		}
		if (panel === this.index || this.current.is(panel)) {
			if (callback) {
				callback();
			}
			return;
		}
		panel = $(isInt(panel) ? this.els.panels[panel] : panel);
		const outgoingPanel = this.current,
			incomingPanel = panel;
		if (!this._isAnimating && incomingPanel.length) {
			const outgoingAnimation = outgoingPanel.nextAll().is(incomingPanel)
				? this._animation.top
				: this._animation.bottom;
			/**
			 * @event ConflictPager#pagerTransitionStart
			 * @type {CustomEvent}
			 * @property {Object} detail
			 * @property {jQuery} detail.from The outgoing conflict panel.
			 * @property {jQuery} detail.to The incoming conflict panel.
			 */
			dispatchCustomEvent("pagerTransitionStart", this.els.emitter, {
				bubbles: true,
				detail: {
					from: outgoingPanel,
					to: incomingPanel,
				},
			});
			this._isAnimating = true;

			outgoingPanel
				.removeClass(this.classes.visible)
				.children()
				.first()
				.velocity(outgoingAnimation, this.duration, this.easing)
				.end();
			incomingPanel
				.addClass(this.classes.visible)
				.children()
				.first()
				.velocity(this._animation.visible, this.duration, this.easing, () => {
					this._isAnimating = false;
					/**
					 * @event ConflictPager#pagerTransitionEnd
					 * @property {Object} detail
					 * @property {jQuery} detail.from The outgoing conflict panel.
					 * @property {jQuery} detail.to The incoming conflict panel.
					 */
					dispatchCustomEvent("pagerTransitionEnd", this.els.emitter, {
						bubbles: true,
						detail: {
							to: incomingPanel,
							from: outgoingPanel,
						},
					});
				});
			outgoingPanel.find("video").each((_i, el) => el.pause());
			$(this.els.emitter).one("pagerTransitionEnd", () => {
				incomingPanel.find(".pager-hide-js").removeClass("pager-hide-js");
				if (!MediaManager.reduceMotion()) {
					incomingPanel.find("video").each((_i, el) => !el.error && el.play());
				}
				if (callback) {
					callback();
				}
			});
			this._currentScroller = incomingPanel.find(".header-content-scroll")[0];
		}
		this.resetScroll();
	}

	/**
	 * Go to previous conflict.
	 * ! Calls preventDefault() when used as an event handler
	 * @param {Event} event Optional.
	 */
	prev(event) {
		if (event) {
			event.preventDefault();
		}
		this.goTo(this.current.prev(`.${this.classes.panel}`));
	}

	/**
	 * Go to next conflict.
	 * ! Calls preventDefault() when used as an event handler
	 * @param {Event} event Optional.
	 */
	next(event) {
		if (event) {
			event.preventDefault();
		}
		this.goTo(this.current.next(`.${this.classes.panel}`));
	}

	/**
	 * Expand current conflict and fetch and insert conflict content.
	 */
	expand() {
		if (this.isExpanded) {
			return;
		}
		this.current
			.addClass(`${this.classes.expanded}`)
			.parent()
			.addClass(`has-${this.classes.expanded}`);
		// this.current.find("video").each((_i, el) => el.pause());
		this._isScrollBlocked = true;
		/**
		 * @event ConflictPager#pagerExpanded
		 * @type {CustomEvent}
		 * @property {Object} detail
		 * @property {jQuery} detail.panel The current panel.
		 */
		dispatchCustomEvent("pagerExpanded", this.els.emitter, {
			bubbles: true,
			detail: { panel: this.current },
		});
	}

	/**
	 * Collapse current conflict and remove added content
	 */
	collapse() {
		if (!this.isExpanded) {
			return;
		}
		this.current
			.removeClass(`${this.classes.expanded}`)
			.parent()
			.removeClass(`has-${this.classes.expanded}`);
		this.current
			.find("video")
			.each((_i, el) => !MediaManager.reduceMotion() && !el.error && el.play());
		this._isScrollBlocked = false;
		/**
		 * @event ConflictPager#pagerCollapsed
		 * @type {CustomEvent}
		 * @property {Object} detail
		 * @property {jQuery} detail.panel The current panel.
		 */
		dispatchCustomEvent("pagerCollapsed", this.els.emitter, {
			bubbles: true,
			detail: { panel: this.current },
		});
	}

	/**
	 * Toggle expansion of current conflict
	 * @param {Event} event Optional. Passed when used as an event handler.
	 * @param {Boolean} forcedExpansionState Optional. Force the current conflict to expanded (`true`) or not (`false`).
	 */
	toggleCurrent(event, forcedExpansionState) {
		if (event && event.preventDefault) {
			event.preventDefault();
		}
		if (
			forcedExpansionState === false ||
			this.current.hasClass(`${this.classes.expanded}`)
		) {
			this.collapse();
			return;
		}
		this.expand();
	}

	resetScroll() {
		this._delta = 0;
		this.checkNavigation();
	}

	/**
	 * Update navigation arrows visibility.
	 */
	checkNavigation() {
		this.els.current.text(this.index + (this.countFirstPanel ? 1 : 0));
		this.els.prev.toggleClass(this.classes.inactive, this.index === 0);
		this.els.next.toggleClass(
			this.classes.inactive,
			this.index === this.els.panels.length - 1
		);
	}

	/**
	 * Remove styles applied via jQuery.
	 */
	resetPanelStyle() {
		this.els.panels
			.children()
			.first()
			.each(function() {
				$(this).attr("style", "");
			});
	}
}

/**
 * Whether the current conflict is expanded.
 * @memberof ConflictPager
 * @member {Boolean} isExpanded
 * @readonly
 */
Object.defineProperty(ConflictPager.prototype, "isExpanded", {
	get: function() {
		return this.current && this.current.hasClass(`${this.classes.expanded}`);
	},
});

/**
 * Whether the pager is active.
 * @memberof ConflictPager
 * @member {Boolean} isActive
 * @readonly
 */
Object.defineProperty(ConflictPager.prototype, "isActive", {
	get: function() {
		return config.breakpoints.up("lg");
	},
});

/* Custom effects registration - feature available in the Velocity UI pack */
$.Velocity.RegisterEffect("translateDown", {
	defaultDuration: 1,
	calls: [[{ translateY: "100%" }, 1]],
});
$.Velocity.RegisterEffect("translateNone", {
	defaultDuration: 1,
	calls: [
		[
			{
				translateY: "0",
				opacity: "1",
				scale: "1",
				rotateX: "0",
				boxShadowBlur: "0",
			},
			1,
		],
	],
});
$.Velocity.RegisterEffect("rotation", {
	defaultDuration: 1,
	calls: [[{ opacity: "0", rotateX: "90", translateY: "-100%" }, 1]],
});
