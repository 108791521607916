import { compatRequestAnimationFrame } from "./common/utils";
const requestAnimFrame = compatRequestAnimationFrame.resolve();
/**
 * @class SiemkaSlider
 * @description Simple slider with added vertical slider support.
 *
 * @extends Siema by Paweł Grzybek
 * @see https://pawelgrzybek.github.io/siema/
 */
export default class SiemkaSlider {
	/**
	 * Create a SiemkaSlider.
	 * @param {Object} options - Optional settings object.
	 * @property {String|HTMLElement} [options.selector=".siemka"]
	 * @property {Number} [options.duration=200]
	 * @property {String} [options.easing="ease-out"]
	 * @property {Number} [options.perPage=1]
	 * @property {Number} [options.startIndex=0]
	 * @property {Boolean} [options.draggable=true]
	 * @property {Boolean} [options.multipleDrag=true]
	 * @property {Number} [options.threshold=20]
	 * @property {Boolean} [options.loop=false]
	 * @property {Boolean} [options.rtl=false]
	 * @property {Boolean} [options.vertical=false]
	 * @property {Function} [options.onInit]
	 * @property {Function} [options.onChange]
	 */
	constructor(options) {
		// Merge defaults with user's settings
		this.config = SiemkaSlider.mergeSettings(options);

		// Resolve selector's type
		this.selector =
			typeof this.config.selector === "string"
				? document.querySelector(this.config.selector)
				: this.config.selector;

		// Early throw if selector doesn't exists
		if (this.selector === null) {
			throw new Error("Something wrong with your selector 😭");
		}

		// update perPage number dependable of user value
		this.resolveSlidesNumber();

		// Create global references

		/** @member {Node[]} innerElements */
		this.innerElements = [].slice.call(this.selector.children);
		/** @member {Number} selectorWidth */
		this.selectorWidth = this.selector.offsetWidth;
		this.selectorHeight = this.calculateSelectorHeight();
		this.currentSlide = this.config.loop
			? this.config.startIndex % this.innerElements.length
			: Math.max(
					0,
					Math.min(
						this.config.startIndex,
						this.innerElements.length - this.perPage
					)
			  );
		this.transformProperty = SiemkaSlider.webkitOrNot();

		// Bind all event handlers for referencability
		[
			"goTo",
			"resizeHandler",
			"touchstartHandler",
			"touchendHandler",
			"touchmoveHandler",
			"mousedownHandler",
			"mouseupHandler",
			"mouseleaveHandler",
			"mousemoveHandler",
			"clickHandler",
			"focusHandler",
		].forEach(method => {
			this[method] = this[method].bind(this);
		});

		// Build markup and apply required styling to elements
		this.init();
	}

	/**
	 * Overrides default settings with custom ones.
	 * @param {Object} options - Optional settings object.
	 * @returns {Object} - Custom SiemkaSlider settings.
	 */
	static mergeSettings(options) {
		const settings = {
			selector: ".siemka",
			duration: 200,
			easing: "ease-out",
			perPage: 1,
			startIndex: 0,
			draggable: true,
			multipleDrag: true,
			threshold: 20,
			loop: false,
			rtl: false,
			vertical: false,
			onInit: () => {},
			onChange: () => {},
		};

		const userSttings = options;
		for (const attrname in userSttings) {
			settings[attrname] = userSttings[attrname];
		}

		return settings;
	}
	/**
	 * Determine if browser supports unprefixed transform property.
	 * Google Chrome since version 26 supports prefix-less transform
	 * @returns {string} - Transform property supported by client.
	 */
	static webkitOrNot() {
		const style = document.documentElement.style;
		if (typeof style.transform === "string") {
			return "transform";
		}
		return "WebkitTransform";
	}

	/**
	 * Calculate height for selector element.
	 * @returns {integer} maximum pixel height of all slides.
	 */
	calculateSelectorHeight() {
		return (
			this.perPage *
			this.innerElements.reduce((carry, element) => {
				return Math.max(carry, element.offsetHeight);
			}, 0)
		);
	}

	/**
	 * Attaches listeners to required events.
	 */
	attachEvents() {
		// Resize element on window resize
		window.addEventListener("resize", this.resizeHandler);

		// If element is draggable / swipable, add event handlers
		if (this.config.draggable) {
			// Keep track pointer hold and dragging distance
			this.pointerDown = false;
			this.drag = {
				startX: 0,
				endX: 0,
				startY: 0,
				endY: 0,
				letItGo: null,
				preventClick: false,
			};

			// Touch events
			this.selector.addEventListener("touchstart", this.touchstartHandler);
			this.selector.addEventListener("touchend", this.touchendHandler);
			this.selector.addEventListener("touchmove", this.touchmoveHandler);

			// Mouse events
			this.selector.addEventListener("mousedown", this.mousedownHandler);
			this.selector.addEventListener("mouseup", this.mouseupHandler);
			this.selector.addEventListener("mouseleave", this.mouseleaveHandler);
			this.selector.addEventListener("mousemove", this.mousemoveHandler);

			// Click
			this.selector.addEventListener("click", this.clickHandler);
			this.selector.addEventListener("focusin", this.focusHandler);
		}
	}
	/**
	 * Detaches listeners from required events.
	 */
	detachEvents() {
		window.removeEventListener("resize", this.resizeHandler);
		this.selector.removeEventListener("touchstart", this.touchstartHandler);
		this.selector.removeEventListener("touchend", this.touchendHandler);
		this.selector.removeEventListener("touchmove", this.touchmoveHandler);
		this.selector.removeEventListener("mousedown", this.mousedownHandler);
		this.selector.removeEventListener("mouseup", this.mouseupHandler);
		this.selector.removeEventListener("mouseleave", this.mouseleaveHandler);
		this.selector.removeEventListener("mousemove", this.mousemoveHandler);
		this.selector.removeEventListener("click", this.clickHandler);
		this.selector.removeEventListener("focusin", this.focusHandler);
	}
	/**
	 * Builds the markup and attaches listeners to required events.
	 */
	init() {
		this.attachEvents();

		// hide everything out of selector's boundaries
		this.selector.style.overflow = "hidden";

		// rtl or ltr
		this.selector.style.direction = this.config.rtl ? "rtl" : "ltr";

		// build a frame and slide to a currentSlide
		this.buildSliderFrame();

		this.config.onInit.call(this);
	}
	/**
	 * Build a sliderFrame and slide to a current item.
	 */
	buildSliderFrame() {
		const widthItem = this.selectorWidth / this.perPage;
		const heightItem = this.selectorHeight / this.perPage;
		const itemsToBuild = this.config.loop
			? this.innerElements.length + 2 * this.perPage
			: this.innerElements.length;

		// Create frame and apply styling
		this.sliderFrame = document.createElement("div");
		if (this.config.vertical) {
			this.selector.style.height = `${this.selectorHeight}px`;
			this.sliderFrame.style.height = `${heightItem * itemsToBuild}px`;
		} else {
			this.sliderFrame.style.width = `${widthItem * itemsToBuild}px`;
		}
		this.enableTransition();

		if (this.config.draggable) {
			this.selector.style.cursor = "-webkit-grab";
		}

		// Create a document fragment to put slides into it
		const docFragment = document.createDocumentFragment();

		// Loop through the slides, add styling and add them to document fragment
		if (this.config.loop) {
			for (
				let i = this.innerElements.length - this.perPage;
				i < this.innerElements.length;
				i++
			) {
				const element = this.buildSliderFrameItem(
					this.innerElements[i].cloneNode(true)
				);
				docFragment.appendChild(element);
			}
		}
		for (let i = 0; i < this.innerElements.length; i++) {
			const element = this.buildSliderFrameItem(this.innerElements[i]);
			docFragment.appendChild(element);
		}
		if (this.config.loop) {
			for (let i = 0; i < this.perPage; i++) {
				const element = this.buildSliderFrameItem(
					this.innerElements[i].cloneNode(true)
				);
				docFragment.appendChild(element);
			}
		}

		// Add fragment to the frame
		this.sliderFrame.appendChild(docFragment);

		// Clear selector (just in case something is there) and insert a frame
		this.selector.innerHTML = "";
		this.selector.appendChild(this.sliderFrame);

		// Go to currently active slide after initial build
		this.slideToCurrent();
	}

	buildSliderFrameItem(elm) {
		const elementContainer = document.createElement("div");
		if (this.config.vertical) {
			elementContainer.style.height = `calc(${this.selectorHeight}px / ${this.perPage})`;
			elementContainer.style.width = "100%";
		} else {
			elementContainer.style.cssFloat = this.config.rtl ? "right" : "left";
			elementContainer.style.float = this.config.rtl ? "right" : "left";
			elementContainer.style.width = `${100 /
				(this.config.loop
					? this.innerElements.length + this.perPage * 2
					: this.innerElements.length)}%`;
		}
		elementContainer.appendChild(elm);
		return elementContainer;
	}
	/**
	 * Determinates slides number accordingly to clients viewport.
	 */
	resolveSlidesNumber() {
		if (typeof this.config.perPage === "number") {
			this.perPage = this.config.perPage;
		} else if (typeof this.config.perPage === "object") {
			this.perPage = 1;
			for (const viewport in this.config.perPage) {
				if (window.innerWidth >= viewport) {
					this.perPage = this.config.perPage[viewport];
				}
			}
		}
	}

	get hasNext() {
		return (
			this.config.loop ||
			this.currentSlide + this.perPage < this.innerElements.length
		);
	}

	get hasPrev() {
		return this.config.loop || this.currentSlide > 0;
	}

	goBy(howManySlides, callback) {
		// early return when there is nothing to slide
		if (this.innerElements.length <= this.perPage || !howManySlides) {
			return;
		}

		const beforeChange = this.currentSlide;
		const isGoingForward = howManySlides < 0;
		const destIndex = this.currentSlide + howManySlides;

		if (this.config.loop) {
			const isNewIndexClone = isGoingForward
				? destIndex > this.innerElements.length - this.perPage
				: destIndex < 0;

			if (isNewIndexClone) {
				this.disableTransition();

				const mirrorSlideIndex =
					this.currentSlide +
					(isGoingForward ? -1 : 1) * this.innerElements.length;
				const mirrorSlideIndexOffset = this.perPage;
				const moveTo = mirrorSlideIndex + mirrorSlideIndexOffset;
				const offset = {
					x:
						(this.config.rtl ? 1 : -1) *
						moveTo *
						(this.selectorWidth / this.perPage),
					y: -moveTo * (this.selectorHeight / this.perPage),
				};
				const dragDistance = {
					x: this.config.draggable ? this.drag.endX - this.drag.startX : 0,
					y: this.config.draggable ? this.drag.endY - this.drag.startY : 0,
				};
				const transform = {
					x: this.config.vertical ? 0 : offset.x + dragDistance.x,
					y: this.config.vertical ? offset.y + dragDistance.y : 0,
				};

				this.sliderFrame.style[
					this.transformProperty
				] = `translate3d(${transform.x}px, ${transform.y}px, 0)`;

				this.currentSlide = mirrorSlideIndex + howManySlides;
			} else {
				this.currentSlide = destIndex;
			}
		} else {
			this.currentSlide = Math.max(
				0,
				Math.min(destIndex, this.innerElements.length - this.perPage)
			);
		}

		if (beforeChange !== this.currentSlide) {
			this.slideToCurrent(this.config.loop);
			this.config.onChange.call(this);
			if (callback) {
				callback.call(this);
			}
		}
	}

	/**
	 * Go to previous slide.
	 * @param {number} [howManySlides=1] - How many items to slide backward.
	 * @param {function} callback - Optional callback function.
	 */
	prev(howManySlides = 1, callback) {
		this.goBy(-howManySlides, callback);
	}
	/**
	 * Go to next slide.
	 * @param {number} [howManySlides=1] - How many items to slide forward.
	 * @param {function} callback - Optional callback function.
	 */
	next(howManySlides = 1, callback) {
		this.goBy(howManySlides, callback);
	}
	/**
	 * Disable transition on sliderFrame.
	 */
	disableTransition() {
		this.sliderFrame.style.webkitTransition = `all 0ms ${this.config.easing}`;
		this.sliderFrame.style.transition = `all 0ms ${this.config.easing}`;
	}
	/**
	 * Enable transition on sliderFrame.
	 */
	enableTransition() {
		this.sliderFrame.style.webkitTransition = `all ${this.config.duration}ms ${this.config.easing}`;
		this.sliderFrame.style.transition = `all ${this.config.duration}ms ${this.config.easing}`;
	}
	/**
	 * Go to slide with particular index
	 * @param {Number} index - Item index to slide to.
	 * @param {Function} callback - Optional callback function.
	 */
	goTo(index, callback) {
		if (this.innerElements.length <= this.perPage) {
			return;
		}
		const beforeChange = this.currentSlide;
		this.currentSlide = this.config.loop
			? index % this.innerElements.length
			: Math.min(Math.max(index, 0), this.innerElements.length - this.perPage);
		if (beforeChange !== this.currentSlide) {
			this.slideToCurrent();
			this.config.onChange.call(this);
			if (callback) {
				callback.call(this);
			}
		}
	}
	/**
	 * Moves sliders frame to position of currently active slide
	 */
	slideToCurrent(enableTransition) {
		const currentSlide = this.config.loop
			? this.currentSlide + this.perPage
			: this.currentSlide;
		const offset = {
			x: this.config.vertical
				? 0
				: (this.config.rtl ? 1 : -1) *
				  currentSlide *
				  (this.selectorWidth / this.perPage),
			y: this.config.vertical
				? -currentSlide * (this.selectorHeight / this.perPage)
				: 0,
		};

		if (enableTransition) {
			// This one is tricky, I know but this is a perfect explanation:
			// https://youtu.be/cCOL7MC4Pl0
			requestAnimationFrame(() => {
				requestAnimationFrame(() => {
					this.enableTransition();
					this.sliderFrame.style[
						this.transformProperty
					] = `translate3d(${offset.x}px, ${offset.y}px, 0)`;
				});
			});
		} else {
			this.sliderFrame.style[
				this.transformProperty
			] = `translate3d(${offset.x}px, ${offset.y}px, 0)`;
		}
	}
	/**
	 * Recalculate drag /swipe event and reposition the frame of a slider
	 */
	updateAfterDrag() {
		let movement = (this.config.rtl ? -1 : 1) * (this.drag.endX - this.drag.startX);
		if (this.config.vertical) {
			movement = this.drag.endY - this.drag.startY;
		}
		const movementDistance = Math.abs(movement);
		const howManySliderToSlide = this.config.multipleDrag
			? Math.ceil(movementDistance / (this.selectorWidth / this.perPage))
			: 1;

		const slideToNegativeClone =
			movement > 0 && this.currentSlide - howManySliderToSlide < 0;
		const slideToPositiveClone =
			movement < 0 &&
			this.currentSlide + howManySliderToSlide >
				this.innerElements.length - this.perPage;

		if (
			movement > 0 &&
			movementDistance > this.config.threshold &&
			this.innerElements.length > this.perPage
		) {
			this.prev(howManySliderToSlide);
		} else if (
			movement < 0 &&
			movementDistance > this.config.threshold &&
			this.innerElements.length > this.perPage
		) {
			this.next(howManySliderToSlide);
		}
		this.slideToCurrent(slideToNegativeClone || slideToPositiveClone);
	}
	/**
	 * When window resizes, resize slider components as well
	 */
	resizeHandler() {
		// update perPage number dependable of user value
		this.resolveSlidesNumber();

		// relcalculate currentSlide
		// prevent hiding items when browser width increases
		if (this.currentSlide + this.perPage > this.innerElements.length) {
			this.currentSlide =
				this.innerElements.length <= this.perPage
					? 0
					: this.innerElements.length - this.perPage;
		}

		this.selectorWidth = this.selector.offsetWidth;
		this.selectorHeight = this.calculateSelectorHeight();

		this.buildSliderFrame();
	}
	/**
	 * Clear drag after touchend and mouseup event
	 */
	clearDrag() {
		this.drag = {
			startX: 0,
			endX: 0,
			startY: 0,
			endY: 0,
			letItGo: null,
			preventClick: this.drag.preventClick,
		};
	}
	/**
	 * touchstart event handler
	 */
	touchstartHandler(e) {
		// Prevent dragging / swiping on inputs, selects and textareas
		const ignoreSiema =
			["TEXTAREA", "OPTION", "INPUT", "SELECT"].indexOf(e.target.nodeName) !== -1;
		if (ignoreSiema) {
			return;
		}

		e.stopPropagation();
		this.pointerDown = true;
		this.drag.startX = e.touches[0].pageX;
		this.drag.startY = e.touches[0].pageY;
	}
	/**
	 * touchend event handler
	 */
	touchendHandler(e) {
		e.stopPropagation();
		this.pointerDown = false;
		this.enableTransition();
		if (this.config.vertical ? this.drag.endY : this.drag.endX) {
			this.updateAfterDrag();
		}
		this.clearDrag();
	}
	/**
	 * touchmove event handler
	 */
	touchmoveHandler(e) {
		e.stopPropagation();

		if (this.drag.letItGo === null) {
			const magY = Math.abs(this.drag.startY - e.touches[0].pageY);
			const magX = Math.abs(this.drag.startX - e.touches[0].pageX);
			this.drag.letItGo = this.config.vertical ? magY > magX : magY < magX;
			// this.drag.letItGo = Math.abs(this.drag.startY - e.touches[0].pageY) < Math.abs(this.drag.startX - e.touches[0].pageX);
		}

		if (this.pointerDown && this.drag.letItGo) {
			e.preventDefault();
			this.drag.endX = e.touches[0].pageX;
			this.drag.endY = e.touches[0].pageY;
			this.sliderFrame.style.webkitTransition = `all 0ms ${this.config.easing}`;
			this.sliderFrame.style.transition = `all 0ms ${this.config.easing}`;

			const currentSlide = this.config.loop
				? this.currentSlide + this.perPage
				: this.currentSlide;
			const currentOffset = {
				x: currentSlide * (this.selectorWidth / this.perPage),
				y: currentSlide * (this.selectorHeight / this.perPage),
			};
			const dragOffset = {
				x: this.drag.endX - this.drag.startX,
				y: this.drag.endY - this.drag.startY,
			};
			if (this.config.vertical) {
				const offset = currentOffset.y - dragOffset.y;
				this.sliderFrame.style[
					this.transformProperty
				] = `translate3d(0, ${-offset}px, 0)`;
			} else {
				const offset = this.config.rtl
					? currentOffset.x + dragOffset.x
					: currentOffset.x - dragOffset.x;
				this.sliderFrame.style[this.transformProperty] = `translate3d(${(this
					.config.rtl
					? 1
					: -1) * offset}px, 0, 0)`;
			}
		}
	}
	/**
	 * mousedown event handler
	 */
	mousedownHandler(e) {
		// Prevent dragging / swiping on inputs, selects and textareas
		const ignoreSiema =
			["TEXTAREA", "OPTION", "INPUT", "SELECT"].indexOf(e.target.nodeName) !== -1;
		if (ignoreSiema) {
			return;
		}

		e.preventDefault();
		e.stopPropagation();
		this.pointerDown = true;
		this.drag.startX = e.pageX;
		this.drag.startY = e.pageY;
	}
	/**
	 * mouseup event handler
	 */
	mouseupHandler(e) {
		e.stopPropagation();
		this.pointerDown = false;
		this.selector.style.cursor = "-webkit-grab";
		this.enableTransition();
		if (this.config.vertical ? this.drag.endY : this.drag.endX) {
			this.updateAfterDrag();
		}
		this.clearDrag();
	}
	/**
	 * mousemove event handler
	 */
	mousemoveHandler(e) {
		e.preventDefault();
		if (this.pointerDown) {
			// if dragged element is a link
			// mark preventClick prop as a true
			// to detemine about browser redirection later on
			if (e.target.nodeName === "A") {
				this.drag.preventClick = true;
			}

			this.drag.endX = e.pageX;
			this.drag.endY = e.pageY;
			this.selector.style.cursor = "-webkit-grabbing";
			this.sliderFrame.style.webkitTransition = `all 0ms ${this.config.easing}`;
			this.sliderFrame.style.transition = `all 0ms ${this.config.easing}`;

			const currentSlide = this.config.loop
				? this.currentSlide + this.perPage
				: this.currentSlide;
			const currentOffset = {
				x: currentSlide * (this.selectorWidth / this.perPage),
				y: currentSlide * (this.selectorHeight / this.perPage),
			};
			const dragOffset = {
				x: this.drag.endX - this.drag.startX,
				y: this.drag.endY - this.drag.startY,
			};

			if (this.config.vertical) {
				const offset = currentOffset.y - dragOffset.y;
				this.sliderFrame.style[
					this.transformProperty
				] = `translate3d(0, ${-offset}px, 0)`;
			} else {
				const offset = this.config.rtl
					? currentOffset.x + dragOffset.x
					: currentOffset.x - dragOffset.x;
				this.sliderFrame.style[this.transformProperty] = `translate3d(${(this
					.config.rtl
					? 1
					: -1) * offset}px, 0, 0)`;
			}
		}
	}
	/**
	 * mouseleave event handler
	 */
	mouseleaveHandler(e) {
		if (this.pointerDown) {
			this.pointerDown = false;
			this.selector.style.cursor = "-webkit-grab";
			this.drag.endX = e.pageX;
			this.drag.endY = e.pageY;
			this.drag.preventClick = false;
			this.enableTransition();
			this.updateAfterDrag();
			this.clearDrag();
		}
	}
	/**
	 * click event handler
	 */
	clickHandler(e) {
		// if the dragged element is a link
		// prevent browsers from folowing the link
		if (this.drag.preventClick) {
			e.preventDefault();
		}
		this.drag.preventClick = false;
	}

	/**
	 * focusin event handler
	 * @param {UIEvent}
	 */
	focusHandler(e) {
		const src = e.target;
		requestAnimFrame(() => {
			const index = this.innerElements.findIndex(el => el.contains(src));
			this.selector.scrollTop = 0;
			this.goTo(index);
		});
	}

	/**
	 * Remove item from carousel.
	 * @param {Number} index - Item index to remove.
	 * @param {Function} callback - Optional callback to call after remove.
	 */
	remove(index, callback) {
		if (index < 0 || index >= this.innerElements.length) {
			throw new Error("Item to remove doesn't exist 😭");
		}

		// Shift sliderFrame back by one item when:
		// 1. Item with lower index than currenSlide is removed.
		// 2. Last item is removed.
		const lowerIndex = index < this.currentSlide;
		const lastItem = this.currentSlide + this.perPage - 1 === index;

		if (lowerIndex || lastItem) {
			this.currentSlide--;
		}

		this.innerElements.splice(index, 1);

		// build a frame and slide to a currentSlide
		this.buildSliderFrame();

		if (callback) {
			callback.call(this);
		}
	}
	/**
	 * Insert item to carousel at particular index.
	 * @param {HTMLElement} item - Item to insert.
	 * @param {number} index - Index of new new item insertion.
	 * @param {function} callback - Optional callback to call after insert.
	 */
	insert(item, index, callback) {
		if (index < 0 || index > this.innerElements.length + 1) {
			throw new Error("Unable to inset it at this index 😭");
		}
		if (this.innerElements.indexOf(item) !== -1) {
			throw new Error("The same item in a carousel? Really? Nope 😭");
		}

		// Avoid shifting content
		const shouldItShift = index <= this.currentSlide > 0 && this.innerElements.length;
		this.currentSlide = shouldItShift ? this.currentSlide + 1 : this.currentSlide;

		this.innerElements.splice(index, 0, item);

		// build a frame and slide to a currentSlide
		this.buildSliderFrame();

		if (callback) {
			callback.call(this);
		}
	}
	/**
	 * Prepernd item to carousel.
	 * @param {HTMLElement} item - Item to prepend.
	 * @param {function} callback - Optional callback to call after prepend.
	 */
	prepend(item, callback) {
		this.insert(item, 0);
		if (callback) {
			callback.call(this);
		}
	}
	/**
	 * Append item to carousel.
	 * @param {HTMLElement} item - Item to append.
	 * @param {function} callback - Optional callback to call after append.
	 */
	append(item, callback) {
		this.insert(item, this.innerElements.length + 1);
		if (callback) {
			callback.call(this);
		}
	}
	/**
	 * Removes listeners and optionally restores to initial markup
	 * @param {boolean} restoreMarkup - Determinants about restoring an initial markup.
	 * @param {function} callback - Optional callback function.
	 */
	destroy(restoreMarkup = false, callback) {
		this.detachEvents();

		this.selector.style.cursor = "auto";

		if (restoreMarkup) {
			const slides = document.createDocumentFragment();
			for (let i = 0; i < this.innerElements.length; i++) {
				slides.appendChild(this.innerElements[i]);
			}
			this.selector.innerHTML = "";
			this.selector.appendChild(slides);
			this.selector.removeAttribute("style");
		}

		if (callback) {
			callback.call(this);
		}
	}
}
