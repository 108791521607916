import { FREQ, compatRequestAnimationFrame, capMinMax } from "./common/utils";
import { MediaManager } from "./common/Media";
import { viewport } from "./_config";

const requestAnimFrame = compatRequestAnimationFrame.resolve();

export function setupElementAnimations(context) {
	if (MediaManager.reduceMotion()) {
		return;
	}
	context = $(context || document);

	context.find("[data-child-class]").each((_i1, parent) => {
		$(parent)
			.children()
			.addClass(parent.dataset.childClass);
	});

	context.find(".fade-in").each(function() {
		this.classList.add("hide-js");
		const watcher = _.throttle(() => {
			const { top, bottom } = this.getBoundingClientRect();
			if (top < window.innerHeight * 0.66 || bottom < window.innerHeight) {
				this.classList.remove("hide-js");
				$(window).off("scroll.element_animations", watcher);
			}
		}, FREQ.FPS_15);
		$(window).on("scroll.element_animations", watcher);
	});

	context.find(".fade-in-each").each(function() {
		$(this)
			.children()
			.addClass("hide-js");
		const delay = parseInt(this.dataset.fadeInDelay || 300, 10);
		const cascadeReveal = function(el) {
			if (!el) {
				return;
			}
			el.classList.remove("hide-js");
			if (el.nextElementSibling) {
				setTimeout(cascadeReveal, delay, el.nextElementSibling);
			}
		};
		const watcher = _.throttle(() => {
			const { top, bottom } = this.getBoundingClientRect();
			if (top < window.innerHeight * 0.75 || bottom < window.innerHeight) {
				cascadeReveal(this.firstElementChild);
				$(window).off("scroll.element_animations", watcher);
			}
		}, FREQ.FPS_15);
		$(window).on("scroll.element_animations", watcher);
	});
}

export function clearElementAnimations() {
	$(window).off(".element_animations");
}

export function setupPinnedElements(pins) {
	const pinHandledSelector = '[data-pin-handled="true"]';
	pins = pins || $(".pin");
	pins.not(pinHandledSelector).each((_i, el) => {
		if (!["sticky", "-webkit-sticky"].includes(getComputedStyle(el).position)) {
			el.parentElement.style.minHeight = `${el.offsetHeight}px`;
			let elScrollOffset = el.parentElement.offsetHeight - el.offsetHeight;
			$(window).on("scroll.sticky_fallback", () => {
				requestAnimFrame(() => {
					if (window.scrollY >= elScrollOffset) {
						el.classList.add("js-stuck");
					} else {
						el.classList.remove("js-stuck");
					}
				});
			});
			$(window).on(
				"resize.sticky_fallback",
				_.throttle(() => {
					el.parentElement.style.minHeight = `${el.offsetHeight}px`;
					elScrollOffset = el.parentElement.offsetHeight - el.offsetHeight;
				}, FREQ.FPS_15)
			);
		} else {
			const sibling = $(el).next();
			const parent = sibling.parent();
			parent.css("min-height", el.offsetHeight + "px");
			sibling.css("margin-top", -1 * el.offsetHeight + "px");
			if (sibling.is("#pivotal-battle")) {
				sibling.css("margin-bottom", el.offsetHeight + "px");
			}
			$(window).on("resize.sticky", () => {
				parent.css("min-height", el.offsetHeight + "px");
				sibling.css("margin-top", -1 * el.offsetHeight + "px");
				if (sibling.is("#pivotal-battle")) {
					sibling.css("margin-bottom", el.offsetHeight + "px");
				}
			});
		}
		el.dataset.pinHandled = true;
	});
}

function calcProgress(pos, lBound, rBound) {
	const scalarPos = Math.abs(pos - lBound);
	const scalarTrack = Math.abs(rBound - lBound);
	return capMinMax(0, 1, scalarPos / scalarTrack);
}

export function getHomePageArrowRenderer() {
	const arrow = $('.home-header-panel a[href="#intro"]')[0],
		introCopy = $("#intro p").first()[0];
	if (!arrow || !introCopy) {
		return;
	}
	const update = _.throttle(function() {
		return { introRect: introCopy.getBoundingClientRect() };
	}, FREQ.FPS_60);
	return function drawHomePageArrow() {
		const { introRect } = update();
		if (introRect.top > viewport.height) {
			arrow.style.opacity = 1;
		} else if (introRect.top <= viewport.height / 2) {
			arrow.style.opacity = 0;
		} else {
			const progress = calcProgress(
				introRect.top,
				viewport.height,
				viewport.height / 2
			);
			arrow.style.opacity = 1 - progress;
		}
	};
}

export function getPivotalBattleRenderer(context) {
	context = $(context || document);
	const wrapper = context.find(".pivotal-battle-wrapper")[0],
		background = context.find(".pivotal-battle-bg")[0],
		section = context.find(".pivotal-battle-wrapper section")[0];
	if (!wrapper || !background) {
		return;
	}
	const opacityBase = background.dataset.baseOpacity || 0.13;
	const opacityRange = 1 - opacityBase;

	const update = _.throttle(function() {
		return {
			wrapRect: wrapper.getBoundingClientRect(),
			sectRect: section.getBoundingClientRect(),
		};
	}, FREQ.FPS_60);

	return function drawPivotalBattle() {
		const { wrapRect, sectRect } = update();
		if (wrapRect.top > 100) {
			background.style.opacity = 1;
		} else if (wrapRect.top >= -viewport.height / 2) {
			// fade out background by the time the wrapper has scrolled 1/2 the viewport height out of the frame
			const progress = calcProgress(wrapRect.top, 100, -viewport.height / 2);
			background.style.opacity = 1 - opacityRange * progress;
		} else if (
			sectRect.bottom <= viewport.height / 2 &&
			wrapRect.bottom > viewport.height
		) {
			// fade in background, keeping opacity at full until the bottom of the wrapper enters the viewport
			const progress = calcProgress(sectRect.bottom, viewport.height / 2, 0);
			background.style.opacity = opacityBase + opacityRange * progress;
		} else if (wrapRect.bottom > viewport.height * 0.5) {
			// fade out background by the time the wrapper bottom hits the middle of the viewport
			const progress = calcProgress(
				wrapRect.bottom,
				viewport.height,
				viewport.height * 0.5
			);
			background.style.opacity = 1 - opacityRange * progress;
		} else {
			background.style.opacity = opacityBase;
		}
	};
}

export function getFactionsBackgroundRenderer() {
	const background = $(".conflict-factions")[0];
	if (MediaManager.reduceMotion() || !background) {
		return;
	}

	const update = _.throttle(function() {
		return {
			bgRect: background.getBoundingClientRect(),
		};
	}, FREQ.FPS_60);

	return function drawFaction() {
		const { bgRect } = update();
		if (bgRect.top > viewport.height) {
			background.style.backgroundPositionY = "0%";
		} else if (bgRect.top > -bgRect.height) {
			// fade out background by the time the wrapper has scrolled 1/2 the viewport height out of the frame
			const progress = calcProgress(bgRect.top, viewport.height, -bgRect.height);
			background.style.backgroundPositionY = progress * 100 + "%";
		} else {
			background.style.backgroundPositionY = "100%";
		}
	};
}
