import MediaManager, { MediaWidthBreakpoint } from "./common/Media";
import { detectBrowser, browsers } from "./common/utils";
import { listenerOptions } from "./common/utils/addons";

const config = {};

const conflicts = document.getElementsByClassName("conflict_template");
// Flags
Object.defineProperty(config, "isHome", {
	get: () => document.body.classList.contains("index"),
});
Object.defineProperty(config, "isConflicts", {
	get: () =>
		/conflicts_index|hur_conflicts_index/i.test(document.body.className) &&
		!conflicts.length,
});
Object.defineProperty(config, "isSingleConflict", {
	get: () => conflicts.lenth === 1,
});

config.breakpoints = new MediaManager([
	new MediaWidthBreakpoint("xs", 0),
	new MediaWidthBreakpoint("sm", 576),
	new MediaWidthBreakpoint("md", 768),
	new MediaWidthBreakpoint("lg", 992),
	new MediaWidthBreakpoint("xl", 1280),
]);

// Component settings
config.conflictsMenu = {
	duration: 800,
	easing: "easeInCubic",
	expansionDuration: 500,
	scrollThreshold: detectBrowser() === browsers.SAFARI ? 1 : 6,
	context: document.documentElement,
};

config.navbar = {
	scrollSpySelector: ".scrollspy",
	scrollSpyOffset: 50,
	context: document.documentElement,
};

config.slider = {
	selector: ".conflict-weapons-slider",
	duration: 200,
	easing: "ease-out",
	perPage: 1,
	startIndex: 0,
	draggable: true,
	multipleDrag: true,
	threshold: 20,
	loop: false,
	rtl: false,
	vertical: true,
	onInit: () => {},
	onChange: () => {},
};

config.router = {
	click: true,
	popstate: true,
	dispatch: false,
	hashbang: false,
	decodeURLComponents: true,
};

export const viewport = {
	width: window.innerWidth,
	height: window.innerHeight,
};

window.addEventListener(
	"resize",
	() => {
		viewport.width = window.innerWidth;
		viewport.height = window.innerHeight;
	},
	listenerOptions.passive
);

export { listenerOptions };

export default config;
