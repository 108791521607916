import page from "page";
import Lightbox from "simple-lightbox";

import "./common/polyfills";
import {
	getJSON,
	stripEnd,
	pathJoin,
	getHTML,
	FREQ,
	TIME,
	compatRequestAnimationFrame,
	compatCancelAnimationFrame,
	isInViewport,
	partial,
	isScrollable,
	isString,
	detectBrowser,
	browsers,
} from "./common/utils";
import { setUpInterfaceDetection, setUpTabFocus } from "./common/utils/addons";
import { MediaManager } from "./common/Media";
import SmoothScroll from "./common/SmoothAnchor";
import ParticlesWrapper from "./_ParticlesWrapper";
import ConflictPager from "./_ConflictPager";
import Slider from "./_slider";
import NavBar from "./_NavBar";
import {
	setupElementAnimations,
	setupPinnedElements,
	getPivotalBattleRenderer,
	getFactionsBackgroundRenderer,
	getHomePageArrowRenderer,
} from "./_animations";

import config, { listenerOptions, viewport } from "./_config";

const { breakpoints } = config;

setUpInterfaceDetection(window, document);
setUpTabFocus(window, document);

/**
 * @var {Object} components NAW Components tracker
 * @prop {SiemkaSlider} [components.slider]
 */
const components = {};

const conflictsMenu = new ConflictPager(config.conflictsMenu);
const nav = new NavBar(config.navbar);

const requestAnimFrame = compatRequestAnimationFrame.resolve();
const cancelAnimFrame = compatCancelAnimationFrame.resolve();

function checkReloadConflicts() {
	const canonicalPath = location.pathname.replace("/hur/", "/");
	const isConflicts = /^\/conflicts\/?(?:\?.*)?$/i.test(canonicalPath);
	const noConflictsMenu = !conflictsMenu.isInitialized && conflictsMenu.isActive;
	if (isConflicts && noConflictsMenu) {
		location.reload();
	}
}

window.addEventListener("pageshow", function(event) {
	var historyTraversal =
		event.persisted || (typeof window.performance != "undefined" && window.performance.navigation.type === 2);
	if (historyTraversal) {
		checkReloadConflicts();
	}
});
window.addEventListener("popstate", checkReloadConflicts);

function setupSamePageLinks() {
	SmoothScroll.debounceDelay = 100;
	SmoothScroll.defaultOffset = -(config.navbar.scrollSpyOffset - 1);
	SmoothScroll.automate();
}

function setupSafariAjaxImages() {
	const safariAjaxImages = $(".inline-image > img, .weapon-desc-bg, .weapon figure > img").not(".ajax-image");
	safariAjaxImages.on("load", function() {
		// this.style.height = `${(this.clientWidth * this.naturalHeight) / this.naturalWidth}px`;
		if (detectBrowser() === browsers.SAFARI) {
			this.removeAttribute("height");
			this.removeAttribute("width");
		}
	});
	if (components.slider) {
		components.slider.resizeHandler();
	}
	// $(window)
	// 	.off("resize.safari_images")
	// 	.on(
	// 		"resize.safari_images",
	// 		_.debounce(() => {
	// 			safariAjaxImages.each((_i, el) => {
	// 				el.style.height = `${(el.clientWidth * el.naturalHeight) / el.naturalWidth}px`;
	// 			});
	// 			if (components.slider) {
	// 				components.slider.resizeHandler();
	// 			}
	// 		}, TIME.SECOND / 2)
	// 	);
	safariAjaxImages.addClass("ajax-image");
}

function setupGallery() {
	components.gallery = new Lightbox({
		$items: $("#synopsis a"),
		beforeSetContent: (_imageWrapper, _lightbox) => {
			$(".slbCloseBtn")
				.addClass("a11y-min-target-before")
				.html('<img src="/assets/media/close.svg" alt="Close">');
		},
	});
}

let animationLoop;
const animationCallbacks = {};

/**
 * Start animation loop
 */
function draw() {
	for (const callback in animationCallbacks) {
		if (animationCallbacks.hasOwnProperty(callback)) {
			animationCallbacks[callback]();
		}
	}
	animationLoop = requestAnimFrame(draw);
}

/**
 * Populate the animationCallbacks object
 * @param {Element|jQuery} context
 */
function setupAnimationLoop(context) {
	const renderHomeArrow = getHomePageArrowRenderer(context);
	const renderPivotalBattle = getPivotalBattleRenderer(context);
	const renderFactions = getFactionsBackgroundRenderer(context);
	if (renderHomeArrow) {
		animationCallbacks.homeArrow = renderHomeArrow;
	} else {
		delete animationCallbacks.homeArrow;
	}
	if (renderPivotalBattle) {
		animationCallbacks.pivotalBattle = renderPivotalBattle;
	} else {
		delete animationCallbacks.pivotalBattle;
	}
	if (renderFactions) {
		animationCallbacks.factions = renderFactions;
	} else {
		delete animationCallbacks.factions;
	}
}

function setupConflictHeaderVideo() {
	if (MediaManager.reduceMotion()) {
		return;
	}
	const context = conflictsMenu && conflictsMenu.isInitialized ? conflictsMenu.current : $(document);
	context.find(".header video").each((_i, el) => {
		$(window).on(
			"scroll",
			_.throttle(() => {
				if (isInViewport(el) && !el.error) {
					el.play();
				} else {
					el.pause();
				}
			}, FREQ.FPS_15)
		);
	});
}

/**
 * Set up conflicts menu & routing
 */
function setupConflictsMenuRoutes() {
	if (naw.i18n.locale !== naw.i18n.mount_at_root) {
		const localePath = naw.i18n.path.replace(":locale", naw.i18n.locale);
		page.base(stripEnd("/", localePath));
	}

	$(".ssb-scroll").on("click", e => {
		if (conflictsMenu.isActive) {
			e.preventDefault();
			e.stopPropagation();
			conflictsMenu.next();
		}
	});

	/**
	 * Check whether the conflicts menu is "active", hard-loading the path if not
	 * @param {PageJS.Context} ctx
	 * @param {PageJS.Callback} next
	 */
	function checkPagerActive(ctx, next) {
		if (!conflictsMenu.isInitialized || !conflictsMenu.isActive || ctx.querystring === "reload") {
			page.stop();
			/* eslint-disable-next-line compat/compat */
			location.href = location.origin + ctx.canonicalPath.replace("?reload", "");
			return;
		}
		next();
	}

	/**
	 * Exit if navigating to same page link or non-existent conflict
	 * @param {PageJS.Context} ctx
	 * @param {PageJS.Callback} next
	 */
	function checkConflict(ctx, next) {
		const { conflict } = ctx.params;
		if (ctx.hash || !naw.data.conflicts.includes(conflict)) {
			return;
		}
		next();
	}

	/**
	 * Reset nav and close open conflict, unfreezing the conflicts menu
	 * @param {PageJS.Context} _ctx
	 * @param {PageJS.Callback} next
	 */
	function exitConflict(_ctx, next) {
		nav.cancelable = false;
		nav.hideSecondary();
		if (components.gallery) {
			components.gallery = components.gallery.destroy();
		}
		if (conflictsMenu.isExpanded) {
			next = partial(setTimeout, next, config.conflictsMenu.expansionDuration);
			conflictsMenu.collapse();
			conflictsMenu.current.find("main").remove();
			conflictsMenu.freeze = false;
			nav.rebuild(null);
		}
		conflictsMenu.current.find(".conflict-trigger[data-href]").each((_i, el) => {
			el.href = el.dataset.href;
			el.removeAttribute("data-href");
		});
		next();
	}

	/**
	 * Make sure conflicts menu is on the proper conflict and freeze it before expansion
	 * @param {PageJS.Context} ctx
	 * @param {PageJS.Callback} next
	 */
	function prepareConflict(ctx, next) {
		conflictsMenu.goTo(`#${ctx.params.conflict}`, () => {
			conflictsMenu.freeze = true;
			next();
		});
	}

	/**
	 * Render a conflict
	 * * expand the conflicts menu
	 * * build out the nav
	 * * set up any animations & listeners
	 * * set up gallery & weapon slider
	 * @param {HTMLCollection|Node[]} conflictElements
	 */
	function renderConflict(conflictElements) {
		// TODO: check order of operations & animations
		conflictsMenu.current.append(conflictElements);
		conflictsMenu.expand();
		conflictsMenu.current.find(".conflict-trigger[href]").each((_i, el) => {
			el.dataset.href = el.href;
			el.removeAttribute("href");
		});
		nav.rebuild(conflictsMenu.current);
		setupConflictHeaderVideo();
		setupElementAnimations(conflictsMenu.current);
		setupSamePageLinks();
		setupSafariAjaxImages();
		setupGallery();
		setupLocation();
		setupWeaponSlider();
		setupPinnedElements(conflictsMenu.current.find(".pin"));
		matchHeights();
		setTimeout(() => {
			setupAnimationLoop(conflictsMenu.current);
			if (animationLoop) {
				cancelAnimFrame(animationLoop);
			}
			$(window)
				.trigger("scroll")
				.trigger("resize");
			draw();
		}, TIME.SECOND / 2);
	}

	/**
	 * Fetch conflict data & render
	 * @param {PageJS.Context} ctx
	 * @param {PageJS.Callback} next
	 * @async
	 */
	function fetchConflict(ctx, next) {
		const { conflict } = ctx.params;
		// Load from pushState if available
		if (ctx.state[conflict]) {
			// Retrieve and rehydrate from HTML string
			nav.cancelable = true;
			renderConflict($.parseHTML(ctx.state[conflict], document, true));
			next();
			return;
		}
		// Else fetch content
		let url = pathJoin(ctx.path, "/partial.html");
		if (naw.i18n.locale !== "en") {
			url = "/" + naw.i18n.locale + url;
		}
		getHTML(url)
			.then(({ response }) => {
				// Store response as HTML string
				ctx.state[conflict] = response.body.innerHTML;
				ctx.save();
				nav.cancelable = true;
				renderConflict(response.body.children);
				next();
			})
			.catch(console.error);
	}

	/**
	 * Set the document title
	 */
	function setTitle(fallback) {
		fallback = isString(fallback) ? fallback : "";
		let title =
			$("h1")
				.first()
				.text() || fallback;
		if (title) {
			title += " | ";
		}
		document.title = title + naw.text.title;
	}

	/**
	 * Make sure nav is transparent
	 * @param {PageJS.Context} _ctx
	 * @param {PageJS.Callback} next
	 * @async
	 */
	function clearNav(_ctx, next) {
		nav.root.removeClass("nav-fill").removeClass(`${nav.color}-bg`);
		next();
	}

	page("*", checkPagerActive);
	page("/conflicts/:conflict", checkConflict, exitConflict, prepareConflict, fetchConflict, setTitle);
	page("/conflicts/", exitConflict, clearNav, setTitle.bind(document, naw.text.conflicts));

	$(document)
		.on("pagerActivate", () => {
			page.start(config.router);
		})
		.on("pagerDeactivate", () => page.stop());

	if (conflictsMenu.isActive) {
		page.start(config.router);
	}
}

/**
 * Create and configure Particles.js instances
 */
function setupParticles(options) {
	// tweaking options, can be moved to config file
	options.particles.number.value /= 5;
	options.particles.line_linked.distance *= 2;

	if (MediaManager.reduceMotion()) {
		return;
	}

	$(".particles-js").each((i, el) => {
		el.id = el.id || `particle-host-${i}`;
		window.particlesJS(el.id, options);
	});

	if (!window.pJSDom.length) {
		return;
	}
	components.particleManagers = [];

	// Configure particles.js instances
	window.pJSDom.forEach(({ pJS }) => {
		const particles = new ParticlesWrapper(pJS);
		components.particleManagers.push(particles);

		// --- General setup ---
		// Restart particles when window enters lg breakpoint and above
		breakpoints.on("lg", "up", () => {
			particles.restart();
			if (conflictsMenu.isInitialized && conflictsMenu.isExpanded) {
				// If conflict is expanded, pause after (hopefully just) 1 frame
				requestAnimationFrame(particles.pause);
			}
		});
		// Stop particles when window enters md breakpoint and below
		breakpoints.on("md", "down", particles.stop);
		// If already at or below md breakpoint, stop particles
		breakpoints.down("md", particles.stop);
		// --- Conflicts pages setup ---
		if (!conflictsMenu.isInitialized) {
			return;
		}
		const relevantPanel = $(pJS.canvas.el).closest(`.${conflictsMenu.classes.panel}`);

		// Only run particle animations on visible panel
		document.addEventListener(
			"pagerTransitionStart",
			function() {
				particles.pause();
			},
			listenerOptions.passive
		);
		document.addEventListener(
			"pagerTransitionEnd",
			function() {
				if (conflictsMenu.current.is(relevantPanel)) {
					particles.restart();
					particles.show();
				} else {
					particles.stop();
					particles.hide();
				}
			},
			listenerOptions.passive
		);

		// Pause current particles when conflict is expanded
		document.addEventListener(
			"pagerExpanded",
			() => conflictsMenu.current.is(relevantPanel) && particles.pause(),
			listenerOptions.passive
		);

		// Resume particles when conflict is collapsed
		document.addEventListener(
			"pagerCollapsed",
			() => conflictsMenu.current.is(relevantPanel) && particles.resume(),
			listenerOptions.passive
		);

		window.lastInstPjs = particles;
	});
}

function setupWeaponSlider(weaponSlider) {
	weaponSlider = weaponSlider || $(".conflict-weapons-slider").first();
	// Configure slider
	const sliderSettings = config.slider;
	const context = weaponSlider.parent();
	// Gather nav elements
	const sliderNav = {
		prev: context.find(".slider-prev"),
		next: context.find(".slider-next"),
		current: context.find(".slider-current"),
	};
	sliderSettings.onChange = function() {
		$(".weapon.active").removeClass("active");
		sliderNav.current.text(this.currentSlide + 1);
		sliderNav.next.toggleClass("slider-inactive", !this.hasNext);
		sliderNav.prev.toggleClass("slider-inactive", !this.hasPrev);
	};
	const slider = new Slider(sliderSettings);
	components.slider = slider;

	// Card flipping listeners
	let startY, mouseInitialized;
	weaponSlider
		.find(".weapon")
		.on("mousedown.slider", function(startEvent) {
			// prevent doubling up on mouse clicks -> set flag
			mouseInitialized = true;
			startY = startEvent.clientY;
		})
		.on("mouseup.slider", function(endEvent) {
			// If will not change slides -> flip card
			if (Math.abs(endEvent.clientY - startY) < config.slider.threshold) {
				$(this).toggleClass("active");
			}
		})
		.find("button")
		.on("click.slider", function() {
			// prevent doubling up on mouse clicks
			if (mouseInitialized) {
				return;
			}
			$(this)
				.closest(".weapon")
				.toggleClass("active");
		});
	// prevent doubling up on mouse clicks -> unset flag
	$(document)
		.off("mouseup.slider")
		.on("mouseup.slider", () => (mouseInitialized = false));

	// Enable touch scrolling for overflow
	weaponSlider.find(".weapon-content-scroll").each((_i, el) => {
		const parent = $(el).closest(".weapon");
		$(el).on("touchmove", function(event) {
			if (parent.hasClass("active") && isScrollable(el)) {
				event.stopPropagation();
			}
		});
	});
	// Nav click handlers
	sliderNav.next.on("click.slider", e => {
		e.preventDefault();
		components.slider.next();
	});
	sliderNav.prev.on("click.slider", e => {
		e.preventDefault();
		components.slider.prev();
	});
	setTimeout(() => {
		components.slider.resizeHandler();
	}, TIME.SECOND / 2);
}

function setupHomeHeader(homepageLogo) {
	// Hide nav when homepage logo is in view
	homepageLogo = homepageLogo || $("#home-logo")[0];
	let frameReq = null;
	$(window).on("scroll", () => {
		if (frameReq) {
			return;
		}
		frameReq = requestAnimFrame(() => {
			frameReq = null;
			if (!nav.root) {
				return;
			}
			nav.root.toggleClass("nav-hide-js", isInViewport(homepageLogo));
		});
	});
}

function setupLocation() {
	$(".conflict-category").each((_i, el) => {
		const svg = $(el).find("svg")[0];
		$(el).removeClass("show-active");
		$(window).on(
			"scroll.conflict_category",
			_.throttle(() => {
				const rect = svg.getBoundingClientRect();
				if (rect.bottom < viewport.height * 0.75) {
					requestAnimFrame(() => $(el).addClass("show-active"));
					$(window).off("scroll.conflict_category");
				}
			}, FREQ.FPS_15)
		);
	});
}

function matchHeights(context) {
	context = $(context || document);
	const groups = ["combatant-title", "ally-title"];
	for (const groupId of groups) {
		const group = context.find(`[data-match-height="${groupId}"]`);
		let minHeight = 0;
		group.css("min-height", 0);
		if (breakpoints.down("md")) {
			return;
		}
		group.each((_i, el) => (minHeight = Math.max(minHeight, el.offsetHeight))).css("min-height", minHeight + "px");
	}
}

//==============================================================================
// Document Ready
//==============================================================================

$(function nawReady() {
	setupSamePageLinks();
	const mains = document.getElementsByTagName("main");

	if ($("#nav").length) {
		components.navbar = nav.init();

		$(window).on(
			"resize",
			_.throttle(
				() => {
					if (mains.length) {
						matchHeights(mains);
					}
					if (conflictsMenu.current) {
						conflictsMenu.current.find(".header-content-scroll").each((_i, el) => {
							$(el).toggleClass("block", isScrollable(el).y);
						});
					}
					if (nav.root) {
						const navHeight = nav.root[0].offsetHeight;
						$(".nav-trigger").css({ marginTop: `-${navHeight}px`, marginBottom: `${navHeight}px` });
					}
				},
				FREQ.FPS_15,
				{ leading: true, trailing: true }
			)
		);
	}

	if ($(".pager-deck").length && conflictsMenu) {
		components.conflictsMenu = conflictsMenu.init();
		setupConflictsMenuRoutes();

		$(window).on(
			"scroll",
			_.throttle(() => {
				if (!nav.root || !config.isConflicts || breakpoints.up("lg")) {
					return;
				}
				nav.root.toggleClass("nav-fill inherit-bg", window.scrollY > viewport.width * 0.075);
			}, FREQ.FPS_30)
		);
	}

	if ($(".particles-js").length) {
		getJSON("/assets/javascripts/particlesjs-config.json")
			.then(({ response, status }) => {
				if (status !== 200 || !particlesJS) {
				}
				setupParticles(response);
			})
			.catch(xhr => {
				console.error("Error pJS - XMLHttpRequest status: " + xhr.status);
				console.error("Error pJS - File config not found");
			});
	}

	const homepageLogo = $("#home-logo");
	if (homepageLogo.length) {
		setupHomeHeader(homepageLogo[0]);
	}

	const pinnedElements = $(".pin");
	if (pinnedElements.length) {
		setupPinnedElements(pinnedElements);
	}

	setupElementAnimations();

	if ($(".conflict-main").length) {
		setupConflictHeaderVideo();
		setupGallery();
		setupLocation();
		setupWeaponSlider();
		matchHeights();
	}

	setupAnimationLoop();
	draw();

	setTimeout(() => {
		$(window)
			.trigger("scroll")
			.trigger("resize");
	}, TIME.SECOND / 2);
});

export { config, components };
