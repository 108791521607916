/* globals cancelRequestAnimFrame */

/**
 * Creates an interface to manage Particles.js instances
 *
 * @class ParticlesWrapper
 */
export default class ParticlesWrapper {
	/**
	 * @constructor
	 * @param {pJS} pjsInstance - instance of Particles.js to control
	 */
	constructor(pjsInstance) {
		this.particles = pjsInstance;
		this.pause = this.pause.bind(this);
		this.resume = this.resume.bind(this);
		this.stop = this.stop.bind(this);
		this.restart = this.restart.bind(this);
		this.hide = this.hide.bind(this);
		this.show = this.show.bind(this);
	}

	get canvas() {
		return this.particles.canvas.el;
	}

	set canvas(el) {
		this.stop();
		this.particles.canvas.el = el;
		this.restart();
	}

	/**
	 * Pauses animations without clearing canvas
	 */
	pause() {
		cancelRequestAnimFrame(this.particles.fn.checkAnimFrame);
		cancelRequestAnimFrame(this.particles.fn.drawAnimFrame);
	}

	/**
	 * Resume animations from current positions
	 */
	resume() {
		this.particles.fn.vendors.draw();
	}

	/**
	 * Clears canvas, stops all animations, and reinitializes properties
	 */
	stop() {
		cancelRequestAnimFrame(this.particles.fn.checkAnimFrame);
		cancelRequestAnimFrame(this.particles.fn.drawAnimFrame);
		this.particles.tmp.source_svg = undefined;
		this.particles.tmp.img_obj = undefined;
		this.particles.tmp.count_svg = 0;
		this.particles.fn.particlesEmpty();
		this.particles.fn.canvasClear();
	}

	/**
	 * Restart instance from scratch
	 */
	restart() {
		this.particles.fn.particlesRefresh();
	}

	hide() {
		$(this.canvas).addClass("particles-hide");
	}

	show() {
		$(this.canvas).removeClass("particles-hide");
	}
}
