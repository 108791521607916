import ScrollSpy from "./_ScrollSpy";
import { FREQ } from "./common/utils";
import SmoothAnchor from "./common/SmoothAnchor";
import config from "./_config";

const { breakpoints } = config;

export default class NavBar {
	constructor(options) {
		options = options || {};
		this.options = {
			scrollSpySelector: options.scrollSpySelector || ".scrollspy",
			scrollSpyOffset: options.scrollSpyOffset || 50,
		};

		this.els = {
			context: options.context || document.documentElement,
		};

		this._color = options.color || "inherit";

		const baseClass = "nav";
		this.classes = {
			root: baseClass,
			heading: `${baseClass}-heading`,
			mobile: `${baseClass}-mobile`,
			anchor: `${baseClass}-anchor`,
			body: `${baseClass}-body`,
			lang: `${baseClass}-lang`,
			close: `${baseClass}-close`,
			social: `${baseClass}-social`,
			home: `${baseClass}-home`,
			trigger: `${baseClass}-trigger`,
			hide: `${baseClass}-hide-js`,
		};

		this.init = this.init.bind(this);
		this.rebuild = this.rebuild.bind(this);
		this.onScroll = _.throttle(this.onScroll.bind(this), FREQ.FPS_30);
		this.showSecondary = this.showSecondary.bind(this);
		this.hideSecondary = this.hideSecondary.bind(this);
	}

	init() {
		jQuery($ => {
			const { els, classes, options } = this;
			const context = $(els.context);
			els.root = context.hasClass(classes.root)
				? context
				: context.find(`.${classes.root}`);
			els.home = els.root.find(`.${classes.home}`);
			els.heading = els.root.find(`.${classes.heading}`);
			els.mobile = els.root.find(`.${classes.mobile}`);
			els.body = els.root.find(`.${classes.body}`);
			els.anchors = els.root.find(`.${classes.anchor}`);
			els.lang = els.root.find(`.${classes.lang}`);
			els.close = els.root.find(`.${classes.close}`);
			els.social = els.root.find(`.${classes.social}`);
			els.trigger = els.root
				.next(`.${classes.trigger}`)
				.add(`.${classes.trigger}`)
				.first();
			if (this._scrollSpy) {
				this._scrollSpy = this._scrollSpy.unbindEvents();
			}
			if (els.anchors.length) {
				this._scrollSpy = new ScrollSpy(els.body[0], {
					sectionClass: options.scrollSpySelector,
					offset: options.scrollSpyOffset,
				});
			}
			if (els.mobile.length) {
				const select = els.mobile.find("select")[0];
				els.root.on("scrollSpyActivation", e => {
					select.value = e.detail.hash;
				});
				els.mobile.on("change", "select", function(e) {
					e.preventDefault();
					SmoothAnchor.scroll(
						e.currentTarget.value,
						-(options.scrollSpyOffset - 1)
					);
				});
			}
			$(window).off("scroll.navbar");
			if (els.trigger.length) {
				$(window)
					.on("scroll.navbar", this.onScroll)
					.trigger("scroll");
			}
		});
		return this;
	}

	/**
	 * @returns {jQuery}
	 */
	get root() {
		return this.els.root;
	}

	/**
	 * @returns {Boolean}
	 */
	get cancelable() {
		return this.root.hasClass("cancelable");
	}

	/**
	 * @param {Boolean} isCancelable
	 */
	set cancelable(isCancelable) {
		if (isCancelable) {
			this.root.addClass("cancelable");
		} else {
			this.root.removeClass("cancelable");
		}
	}

	/**
	 * @returns {String}
	 */
	get color() {
		return this._color;
	}

	/**
	 * @param {String} newColor
	 */
	set color(newColor) {
		const oldColorClass = `${this._color}-bg`;
		this.root.removeClass(oldColorClass);
		if (newColor) {
			this.root.addClass(`${newColor}-bg`);
		}
		this._color = newColor;
	}

	get isSecondaryVisible() {
		return this.root.hasClass("nav-fill");
	}

	onScroll() {
		const { els, classes } = this;
		const backgroundClasses = `nav-fill ${this.color}-bg`;

		if (els.trigger[0].getBoundingClientRect().top <= 0) {
			const delayStep = 100;
			els.root.addClass(backgroundClasses);
			els.heading.removeClass(classes.hide);
			els.mobile.removeClass(classes.hide);
			els.anchors.filter("." + classes.hide).each((index, el) => {
				if ($(el).data("navAnimationTimeout")) {
					return;
				}
				$(el).data(
					"navAnimationTimeout",
					setTimeout(
						() => $(el).removeClass(classes.hide),
						delayStep * index * 0.5
					)
				);
			});
		} else {
			if (breakpoints.down("xs")) {
				els.root.toggleClass(backgroundClasses, window.scrollY > 20);
			} else {
				els.root.removeClass(backgroundClasses);
			}
			els.heading.addClass(classes.hide);
			els.mobile.addClass(classes.hide);
			els.anchors
				.each((_i, el) => {
					clearTimeout($(el).data("navAnimationTimeout"));
					$(el).data("navAnimationTimeout", null);
				})
				.addClass(classes.hide);
		}
	}

	showSecondary() {
		const backgroundClasses = `nav-fill ${this.color}-bg`;
		const delayStep = 100;
		this.els.root.addClass(backgroundClasses);
		this.els.heading.removeClass(this.classes.hide);
		this.els.mobile.removeClass(this.classes.hide);
		this.els.anchors.filter("." + this.classes.hide).each((index, el) => {
			if ($(el).data("navAnimationTimeout")) {
				return;
			}
			$(el).data(
				"navAnimationTimeout",
				setTimeout(
					() => $(el).removeClass(this.classes.hide),
					delayStep * index * 0.5
				)
			);
		});
	}

	hideSecondary() {
		const backgroundClasses = `nav-fill ${this.color}-bg`;
		this.els.root.removeClass(backgroundClasses);
		this.els.heading.addClass(this.classes.hide);
		this.els.mobile.addClass(this.classes.hide);
		this.els.anchors
			.each((_i, el) => {
				clearTimeout($(el).data("navAnimationTimeout"));
				$(el).data("navAnimationTimeout", null);
			})
			.addClass(this.classes.hide);
	}

	rebuild(context) {
		if (context === undefined) {
			context = document;
		}
		this.els.body.html("");
		this.els.heading.html("");
		this.els.mobile.find("select").html("");
		const hideSecondary = !this.root.hasClass("nav-fill");
		let main = [],
			targets = [];
		if (context) {
			context = $(context);
			main = context.find("main[data-nav-heading]");
			targets = context
				.find(this.options.scrollSpySelector)
				.filter(".scrollspy[id][data-nav-title]");
		}
		if (targets.length) {
			targets.each((i, el) => {
				let classes = "nav-anchor";
				classes += hideSecondary ? " nav-hide-js" : "";
				classes += i === 0 ? " active" : "";
				this.els.body.append(
					`<li><a href="#${el.id}" class="${classes}">${el.dataset.navTitle}</a></li>`
				);
				this.els.mobile
					.find("select")
					.append(
						`<option value="#${el.id}"${i === 0 ? " selected" : ""}>${
							el.dataset.navTitle
						}</option>`
					);
			});
		}
		if (main.length) {
			this.color = main[0].dataset.color || "inherit";
			this.els.heading
				.toggleClass("nav-hide-js", hideSecondary)
				.html(`<h1 class="h5 tt-upper mb-0">${main[0].dataset.navHeading}</h1>`);
		}
		this.els.lang.find("a").each((_i, el) => {
			el.pathname =
				naw.i18n.locale === "en"
					? "/hur" + location.pathname
					: location.pathname.replace("/hur/", "/");
		});
		this.init();
		return this;
	}
}

/**
 * The current panel.
 * @memberof ConflictPager
 * @member {jQuery} current
 * @readonly
 */
Object.defineProperty(NavBar.prototype, "scrollSpy", {
	set: function(value) {
		if (!this._scrollSpy) {
			return undefined;
		}
		const activate = !!value;
		if (activate) {
			this._scrollSpy.resume();
		} else {
			this._scrollSpy.pause();
		}
		return activate;
	},
	get: function() {
		return this._scrollSpy;
	},
});
