import { scrollIntoView, toInteger, debounce } from "./utils";

let latestFocusTarget = null;

export default class SmoothAnchor {
	/**
	 * The method by which smoothscroll is implemented
	 * @
	 * @static
	 */
	static scroll(hash, offset) {
		let target;
		if (hash instanceof HTMLElement) {
			target = hash;
			hash = `#${target.id}`;
		} else {
			target = document.querySelector(hash);
		}
		if (!target) {
			return;
		}
		history.pushState({}, "", hash);
		target.tabIndex = target.tabIndex || (target.tabIndex === 0 ? 0 : -1);
		SmoothAnchor.latestFocusTarget = target;
		scrollIntoView(target, offset || SmoothAnchor.defaultOffset);
	}

	static buildClickHandler(defaultOffset) {
		defaultOffset = toInteger(defaultOffset) || SmoothAnchor.defaultOffset;
		return function(e) {
			const anchor = e.currentTarget,
				target = document.querySelector(anchor.hash);
			if (!target || !("scrollBehavior" in document.documentElement.style)) {
				return;
			}
			e.preventDefault();
			SmoothAnchor.scroll(
				target,
				anchor.dataset[SmoothAnchor.offsetKey] || defaultOffset
			);
		};
	}

	/**
	 * Get a scroll handler that will focus the targeted element after scrolling
	 */
	static buildScrollHandler(debounceFn, debounceDelay) {
		debounceFn = debounceFn || SmoothAnchor.debounceFn;
		debounceDelay = debounceDelay || SmoothAnchor.debounceDelay;
		return debounceFn(function() {
			if (SmoothAnchor.latestFocusTarget) {
				SmoothAnchor.latestFocusTarget.focus({ preventScroll: true });
			}
			SmoothAnchor.latestFocusTarget = null;
		}, debounceDelay);
	}

	/**
	 * Automatically add handlers to all same-page links
	 * @param {Number} [defaultOffset=0] Optional. Default scroll offset in pixels. Overridden by `data-scroll-offset` or custom data attribute on individual anchors. Default 0.
	 * @param {Function} [debounceFn=debounce] Optional. Debouncing function.
	 * @param {Number} [debounceDelay=100] Optional. Wait time between debounced invocations.
	 */
	static automate(defaultOffset, debounceFn, debounceDelay) {
		if (!("scrollBehavior" in document.documentElement.style)) {
			return;
		}
		const anchors = document.querySelectorAll('a[href^="#"]');
		for (const anchor of anchors) {
			const target = document.querySelector(anchor.hash);
			if (!target || anchor.dataset.hasOwnProperty(SmoothAnchor.dataKey)) {
				return;
			}
			anchor.addEventListener(
				"click",
				SmoothAnchor.buildClickHandler(defaultOffset)
			);
			anchor.dataset[SmoothAnchor.dataKey] = "active";
		}
		window.addEventListener(
			"scroll",
			SmoothAnchor.buildScrollHandler(debounceFn, debounceDelay)
		);
	}

	/**
	 * @member {HTMLElement} latestFocusTarget Most recently targeted element, is focused to after scroll.
	 * @static
	 */
	static get latestFocusTarget() {
		return latestFocusTarget;
	}
	static set latestFocusTarget(value) {
		if (value instanceof HTMLElement) {
			value.tabIndex = value.tabIndex || (value.tabIndex === 0 ? 0 : -1);
		} else {
			value = null;
		}
		latestFocusTarget = value;
		return value;
	}
}

/**
 * @member {String} dataKey
 * @memberOf SmoothAnchor
 * @static
 */
SmoothAnchor.dataKey = "smoothAnchor";

/**
 * @member {String} offsetKey
 * @memberOf SmoothAnchor
 * @static
 */
SmoothAnchor.offsetKey = "smoothAnchorOffset";

/**
 * @member {Function} debounceFn
 * @memberOf SmoothAnchor
 * @static
 */
SmoothAnchor.debounceFn = debounce;

/**
 * @member {Number} debounceDelay
 * @memberOf SmoothAnchor
 * @static
 */
SmoothAnchor.debounceDelay = 100;

/**
 * @member {Number} defaultOffset
 * @memberOf SmoothAnchor
 * @static
 */
SmoothAnchor.defaultOffset = 0;
