import { isNumeric } from "./utils/type";

const landscapeMQ = matchMedia("(orientation: landscape)");
const portraitMQ = matchMedia("(orientation: portrait)");
const lightModeMQ = matchMedia("(prefers-color-scheme: light)");
const darkModeMQ = matchMedia("(prefers-color-scheme: dark)");
const reducedMotionMQ = matchMedia("(prefers-reduced-motion: reduce)");

const QUERY_TYPES = ["up", "down", "only"];
const autopass = (Object.freeze || Object)(matchMedia("all"));

export class MediaWidthBreakpoint {
	constructor(name, minWidth, maxWidth) {
		Object.defineProperties(this, {
			/**
			 * @var {number} min - The breakpoint minimum width in pixels.
			 */
			min: {
				set: function(value) {
					minWidth = value ? Math.max(parseFloat(value), 0) : 0;
					updateQueries();
				},
				get: function() {
					return minWidth;
				},
				configurable: true,
				enumerable: true,
			},

			/**
			 * @var {number} max - The breakpoint maximum width in pixels.
			 */
			max: {
				set: function(value) {
					maxWidth = isNumeric(value) ? parseFloat(value) : undefined;
					updateQueries();
				},
				get: function() {
					return maxWidth;
				},
				configurable: true,
				enumerable: true,
			},

			/**
			 * @var {string} name - The breakpoint name.
			 * @readonly
			 */
			name: {
				value: name,
				writable: false,
			},
		});

		const attachedListeners = {
			up: [],
			down: [],
			only: [],
		};

		/**
		 * Detach listeners from media queries, returning references for later reattachment.
		 * @method detachListeners
		 * @private
		 * @param {String|String[]} queryTypes - Optional. List of query types to detach listeners from. Default is all
		 * @returns {Object} - Object with arrays of detached listeners as properties.
		 */
		const detachListeners = function(queryTypes) {
			const detachedListeners = {};
			if (!queryTypes) {
				queryTypes = QUERY_TYPES;
			} else {
				queryTypes = Array.isArray(queryTypes)
					? queryTypes
					: queryTypes.split(" ");
			}
			queryTypes
				.filter(queryType => QUERY_TYPES.includes(queryType))
				.forEach(function(queryType) {
					while (attachedListeners[queryType].length) {
						const listener = attachedListeners[queryType].pop();
						detachedListeners[queryType].push(listener);
						this[queryType].removeListener(listener);
					}
				});
			return detachedListeners;
		}.bind(this);

		/**
		 * Attach listeners to media queries, stashing references for later removal.
		 * @method attachListeners
		 * @private
		 * @param {Object} listeners - Object with arrays of listeners as properties.
		 */
		const attachListeners = function(listeners) {
			QUERY_TYPES.filter(queryType => listeners.hasOwnProperty(queryType)).forEach(
				function(queryType) {
					listeners[queryType].forEach(listener => {
						attachedListeners[queryType].push(listener);
						this[queryType].addListener(listener);
					});
				}.bind(this)
			);
		}.bind(this);

		/**
		 * Update breakpoint media queries with new max/min and port over any listeners.
		 * @method updateQueries
		 * @private
		 */
		const updateQueries = function() {
			const limbo = detachListeners();
			this.up = !!this.min ? matchMedia(`(min-width: ${this.min}px)`) : autopass;
			this.down = !!this.max ? matchMedia(`(max-width: ${this.max}px)`) : autopass;
			this.only = !!this.max
				? matchMedia(`(min-width: ${this.min}px) and (max-width: ${this.max}px)`)
				: this.up;
			attachListeners(limbo);
		}.bind(this);

		/**
		 * Attach listeners to the "up", "down", and/or "only" queries for this breakpoint.
		 * @method on
		 * @param {String|String[]} queryTypes - List of query types to add the listener to.
		 * @param {EventListener} listener - Listens to the "change" event of the selected query types for this breakpoint.
		 */
		this.on = function(queryTypes, listener) {
			const listeners = {};
			queryTypes = Array.isArray(queryTypes) ? queryTypes : queryTypes.split(" ");
			queryTypes.forEach(function(queryType) {
				listeners[queryType] = [listener];
			});
			attachListeners(listeners);
		};

		/**
		 * Remove listeners from the "up", "down", and/or "only" queries for this breakpoint.
		 * @method off
		 * @param {String|String[]} queryTypes - Optional. List of query types to remove listeners from. Default is all
		 */
		this.off = function(queryTypes) {
			detachListeners(queryTypes);
		};

		this.min = minWidth;
		this.max = maxWidth;
	}

	destroy() {
		this.off();
		this.min = this.max = this.only = this.up = this.down = null;
	}
}

export class MediaManager {
	/**
	 * Construct MediaManager from array of width breakpoints.
	 * * discards breakpoints with repeated min-widths or names
	 * @param {MediaWidthBreakpoint[]} breakpoints
	 */
	constructor(breakpoints) {
		if (breakpoints.length) {
			const minWidths = [],
				names = [];
			this.breakpoints = breakpoints
				.sort(function(a, b) {
					// Sort by increasing min-widths
					a = a.min;
					b = b.min;
					switch (true) {
						case a > b:
							return 1;
						/* eslint-disable-next-line */
						case a == b:
							return 0;
						case a < b:
							return -1;
					}
				})
				.filter(function({ name, min }) {
					// Unique min-widths & names only
					if (minWidths.includes(min) || names.includes(name)) {
						return false;
					}
					minWidths.push(min);
					names.push(name);
					return true;
				})
				.map(function(breakpoint, index, array) {
					// Set max-widths for each breakpoint according to the next min
					if (index < array.length - 1) {
						breakpoint.max = array[index + 1].min - 0.02;
					} else {
						breakpoint.max = undefined;
					}
					return breakpoint;
				});
		}

		/**
		 * @property {MediaWidthBreakpoint} current
		 */
		Object.defineProperty(this, "current", {
			get: function() {
				return this.breakpoints.find(({ only }) => only.matches);
			},
		});

		/**
		 * @method checkBreakpoint
		 * @private
		 * @param {String} breakpointName
		 * @param {String} queryType - "up"|"down"|"only"
		 * @param {MediaManager~QueryCallback} callback
		 * @returns {*} - Undefined if breakpoint doesn't exist, or boolean whether it matches.
		 */
		const checkBreakpoint = function(breakpointName, queryType, callback) {
			const breakpoint = this.getBreakpoint(breakpointName);
			if (!breakpoint) {
				return console.warn(
					`Breakpoint named "${breakpointName}" does not exist`
				);
			}
			if (callback && breakpoint[queryType].matches) {
				callback();
			}
			return breakpoint[queryType].matches;
		}.bind(this);

		/**
		 * Check if minimum and maximum width breakpoint matches.
		 * @method only
		 * @param {String} breakpointName
		 * @param {MediaManager~QueryCallback} callback - Optional. Fires if breakpoint exists and matches.
		 * @returns {*} - Undefined if breakpoint doesn't exist, or boolean whether it matches.
		 */
		this.only = function(breakpointName, callback) {
			return checkBreakpoint(breakpointName, "only", callback);
		};

		/**
		 * Check if minimum width breakpoint matches.
		 * @method up
		 * @param {String} breakpointName
		 * @param {MediaManager~QueryCallback} callback - Optional. Fires if breakpoint exists and matches.
		 * @returns {*} - Undefined if breakpoint doesn't exist, or boolean whether it matches.
		 */
		this.up = function(breakpointName, callback) {
			return checkBreakpoint(breakpointName, "up", callback);
		};

		/**
		 * Check if maximum width breakpoint matches.
		 * @method down
		 * @param {String} breakpointName
		 * @param {MediaManager~QueryCallback} callback - Optional. Fires if breakpoint exists and matches.
		 * @returns {*} - Undefined if breakpoint doesn't exist, or boolean whether it matches.
		 */
		this.down = function(breakpointName, callback) {
			return checkBreakpoint(breakpointName, "down", callback);
		};

		/**
		 * Check if minimum and maximum width breakpoints match.
		 * @method between
		 * @param {String} fromBreakpoint - Minimum breakpoint to match.
		 * @param {String} toBreakpoint - Maximum breakpoint to match.
		 * @param {MediaManager~QueryCallback} callback - Optional. Fires if breakpoints exist and match.
		 * @returns {Boolean} - Whether BOTH breakpoints exist and match.
		 */
		this.between = function(fromBreakpoint, toBreakpoint, callback) {
			const from = checkBreakpoint(fromBreakpoint, "up"),
				to = checkBreakpoint(toBreakpoint, "down");
			if (callback && from && to) {
				callback();
			}
			return from && to;
		};
	}

	/**
	 * Get breakpoint by name
	 * @param {String} breakpointName
	 * @returns
	 */
	getBreakpoint(breakpointName) {
		return this.breakpoints.find(({ name }) => name === breakpointName);
	}

	/**
	 * Check if a CSS query string currently matches.
	 * @method is
	 * @public
	 * @param {String} query - A CSS media query.
	 * @returns {Boolean}
	 */
	is(query) {
		return MediaManager.is(query);
	}
	/**
	 * Attach listeners to a breakpoint
	 * @method off
	 * @public
	 * @param {String} breakpointName - The name of the targeted breakpoint.
	 * @param {String|String[]} queryTypes - List of query types to attach listeners to.
	 * @param {EventListener} listener - Function to fire on query "change" event.
	 * @returns {MediaManager} - Returns self for chaining.
	 */
	on(breakpointName, queryTypes, callback) {
		const breakpoint = this.getBreakpoint(breakpointName);
		if (breakpoint) {
			breakpoint.on(queryTypes, callback);
		} else {
			console.warn(`Breakpoint named "${breakpointName}" does not exist`);
		}
		return this;
	}

	/**
	 * Remove listeners from a breakpoint
	 * @method off
	 * @public
	 * @param {String} breakpointName - The name of the targeted breakpoint.
	 * @param {String|String[]} queryTypes - Optional. List of query types to remove listeners from. Default is all.
	 * @returns {MediaManager} - Returns self for chaining.
	 */
	off(breakpointName, queryTypes) {
		const breakpoint = this.getBreakpoint(breakpointName);
		if (breakpoint) {
			breakpoint.off(queryTypes);
		} else {
			console.warn(`Breakpoint named "${breakpointName}" does not exist`);
		}
		return this;
	}

	/**
	 * Check if a CSS query string currently matches.
	 * @method is
	 * @static
	 * @param {String} query - A CSS media query.
	 * @returns {Boolean}
	 */
	static is(query) {
		const cache = MediaManager.prototype._qCache || {};
		let mediaQuery = cache[query];
		if (!mediaQuery) {
			MediaManager.prototype._qCache[query] = mediaQuery = matchMedia(query);
		}
		return mediaQuery.matches;
	}

	/**
	 * Check if screen orientation is portrait.
	 * @method isPortrait
	 * @static
	 * @returns {Boolean}
	 */
	static isPortrait() {
		return portraitMQ.matches;
	}

	/**
	 * Check if screen orientation is landscape.
	 * @method isLandscape
	 * @static
	 * @returns {Boolean}
	 */
	static isLandscape() {
		return landscapeMQ.matches;
	}

	/**
	 * Check if user has indicated preference for reduced motion.
	 * @method isPortrait
	 * @static
	 * @returns {Boolean}
	 */
	static reduceMotion() {
		return reducedMotionMQ.matches;
	}

	/**
	 * Check if user has indicated preference for light colour themes.
	 * @method lightMode
	 * @static
	 * @returns {Boolean}
	 */
	static lightMode() {
		return lightModeMQ.matches;
	}

	/**
	 * Check if user has indicated preference for dark colour themes.
	 * @method darkMode
	 * @static
	 * @returns {Boolean}
	 */
	static darkMode() {
		return darkModeMQ.matches;
	}
}
MediaManager.prototype._qCache = {};
/**
 * @callback MediaManager~QueryCallback
 */

export default MediaManager;
